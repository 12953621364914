



















import Component, { mixins } from 'vue-class-component'
import ComponentHelper from '@/mixins/ComponentHelper'
import GrammarNodeLinker from './components/GrammarNodeLinker.vue'

@Component({
  components: {
    GrammarNodeLinker
  }
})
export default class GrammarNodes extends mixins(ComponentHelper) {
}

